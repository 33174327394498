import { Controller } from "@hotwired/stimulus"
import { getBackgroundColorInHex, hexToRGBString, getContrastColorForTagText } from "../helpers"

export default class extends Controller {
  static targets = ["color", "input", "example"]
  static values = { loaded: Boolean }
  lastElement = null
  prefixId = ''
  inputs = []
  transitionOn = false

  connect() {
    console.log('connect1 colors picker simple')
    if (this.hasExampleTarget) {
      this.updateExample()
    }

    this.bindActions()
  }

  // przypisujemy akcje do wszystkich elementow
  bindActions() {
    this.inputTarget.dataset.action = "input->color-picker-simple#updateExample"
    this.colorTargets.forEach(element => { element.dataset.action = "click->color-picker-simple#fillColorFromPicker" })
  }

  fillColorFromPicker(e) {
    const color = getBackgroundColorInHex(e.target)
    this.inputTarget.value = color

    this.updateExample()
  }

  updateExample() {
    const color = this.inputTarget.value
    console.log("color", color)
    const rgb = hexToRGBString(color)
    if (!isNaN(rgb[2])) {
      this.exampleTarget.style.color = color
      this.exampleTarget.style["background-color"] = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.125)`
    } else {
      this.exampleTarget.style.color = getContrastColorForTagText(color)
      this.exampleTarget.style["background-color"] = color
      // this.exampleTarget.style.removeProperty("color")
      // this.exampleTarget.style.removeProperty("background-color")
    }
  }
}
