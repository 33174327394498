import { useTransition } from 'stimulus-use'
import DropdownController from "./dropdown_controller"

export default class extends DropdownController {
  static targets = ["placeholder", "list", "input"]
  static values = { loaded: Boolean }

  eventCreated = false
  prefix = this.getPrefix(this.menuTarget.id)
  menuHidden = false

  connect() {
    console.log('connect1')
    // super.connect()

    const values = this.getInputTargetsValues()

    // this.menuTarget.classList.add('hidden')
    if (values.length < 0) {
      this.placeholderTarget.classList.add('hidden')
    }

    this.startSelect(values)
    this.loadedValue = true

    // useTransition(this, { element: this.menuTarget })
    if (this.menuTarget.classList.contains('hidden')) {
      // this.menuTarget.classList.add('hidden')
      this.menuHidden = true
      useTransition(this, { element: this.menuTarget })
    }
  }

  toggleInput() {
    const hidden = this.menuTarget.classList.contains('hidden')
    if (hidden) {
      this.toggle()
    }
  }

  select(event) {
    const regex = new RegExp("^" + this.prefix + "_")
    const id = event.params.id.replace(regex, "")
    console.log(event.params.active)

    this.selectHelper(this.prefix, id)
  }

  startSelect(values) {
    values.forEach(element => {
      this.selectHelper(this.prefix, element)
    })
  }

  selectHelper(prefix, id) {
    // elementy które odpowiednio pokazujemy / chowamy przy wybraniu danej opcji
    const tick = document.getElementById(prefix + '_tick_' + id)
    const x = document.getElementById(prefix + '_x_' + id)
    const show = document.getElementById(prefix + '_show_' + id)

    if (!show) {
      // jeśli nie znaleźliśmy elementu to najpewniej został usunięty / nie został wczytany
      // nie mamy jak go wyświetlić to po prostu go pomijamy
      console.error(`couldn't find element with id ${prefix}_show_${id}`)
      return
    }

    // pole formularza
    let values = this.getInputTargetsValues()

    if (tick.classList.contains('hidden')) {
      tick.classList.remove('hidden')
      x.classList.remove('hidden')
      show.classList.remove('hidden')
      show.classList.add('flex')

      // zabezpieczenie by nie dodawać kilka razy tego samego elementu
      if (values.indexOf(id) === -1) {
        values.push(id)
      }
    } else {
      tick.classList.add('hidden')
      x.classList.add('hidden')
      show.classList.add('hidden')
      show.classList.remove('flex')
      this.removeInputTargetValue(id)
      values = values.filter(e => e !== id)
    }

    // chowanie placeholdera jeśli jest wybrana co najmniej jedna opcja
    if (values.filter(n => n).length > 0) {
      this.placeholderTarget.classList.add('hidden')
    } else {
      this.placeholderTarget.classList.remove('hidden')
    }

    values.forEach(value => {
      this.setInputTargetValue(value)
    })
  }

  getPrefix(target) {
    const index = target.lastIndexOf('_')
    const prefix = target.slice(0, index)
    return prefix
  }

  getSuffix(target) {
    const index = target.lastIndexOf('_')
    const suffix = target.slice(index + 1)
    return suffix
  }

  getInputTargetsValues() {
    const targets = this.inputTargets.map(t => t.value).filter(n => n)
    return targets
  }

  // ustawiamy wartosc pola jesli nie istnieje juz takie pole z ta wartoscia
  setInputTargetValue(value) {
    const existingField = this.inputTargets.find(target => target.value === value)

    if (existingField === undefined) {
      const emptyField = this.inputTargets.find(target => !target.value)
      if (emptyField) {
        emptyField.setAttribute('value', value)
      } else {
        this.createNewArrayInput()
        this.setInputTargetValue(value)
      }
    }
  }

  // ustawiamy puste value dla pola ktore znajdziemy na podstawie value
  removeInputTargetValue(value) {
    const existingField = this.inputTargets.find(target => target.value === value)
    existingField.setAttribute('value', '')
  }

  createNewArrayInput() {
    const target = this.inputTargets[0]
    const newTarget = target.cloneNode()
    console.log(newTarget)
    newTarget.setAttribute('value', '')
    target.after(newTarget)
  }
}
