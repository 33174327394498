import { jsonGreedyRequest } from '../helpers'
import DropdownController from "./dropdown_controller"

export default class extends DropdownController {
  static targets = ["menu", "input", "list", "output"]

  connect() {
    console.log("greedy-dropdown connect")
    super.connect()
    this.handleJsonLinks()
  }

  handleJsonLinks() {
    const links = this.menuTarget.querySelectorAll("a[href]")
    // const jsonLinks = Array.from(links).filter(link => link.href.includes(".json?"))

    for (const link of links) {
      if (!link.dataset.action) {
        link.dataset.action = "click->greedy-dropdown#click"
      } else {
        link.dataset.action += " click->greedy-dropdown#click"
      }
    }
  }

  click(e) {
    const link = e.target.closest("a")
    console.log(link)
    console.log(this.outputTarget)
    const url = link.href
    const currentOutputInnerHTML = this.outputTarget.innerHTML

    console.log(this.outputTarget)
    console.log(link)

    const changeTargetFn = function() {
      this.outputTarget.innerHTML = link.innerHTML
    }.bind(this)
    const rollbackHandler = function() {
      console.error("Something went wrong - rolling back DOM changes")
      this.outputTarget.innerHTML = currentOutputInnerHTML
    }.bind(this)

    jsonGreedyRequest(url, changeTargetFn, rollbackHandler)
  }
}
