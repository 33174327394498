import DropzoneObject from '../helpers/dropzone_object'
import { Controller } from "@hotwired/stimulus"
import { humanFilesize } from '../helpers'
import { locales } from "../helpers/dropzone_locale"

export default class extends Controller {
  static targets = ['input', 'hiddenInput', 'placeholder', 'mockFile', 'preview', 'template', 'message',
    'progress', 'thumbnail', 'size', 'name', 'uploadprogress', 'errormessage']

  static values = {
    growWidth: String,
    growHeight: String
  }

  actions = [
    'dragenter->intum-dropzone#enter',
    'dragleave->intum-dropzone#leave',
    'drop->intum-dropzone#drop',
    'dragover@document->intum-dropzone#overDoc',
    'dragleave@document->intum-dropzone#leaveDoc',
    'drop@document->intum-dropzone#dropDoc',
    'click->intum-dropzone#clickInput',
  ]

  availableLocales = ["en", "pl"]

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url")
  }

  get maxFiles() {
    return this.element.dataset.maxFiles || 10
  }

  get maxFileSize() {
    return this.element.dataset.maxFileSize || 10000000 // bytes
  }

  get acceptedFiles() {
    return this.element.dataset.acceptedFiles // data-accepted-files="jpg, png"
  }

  addMockFiles(files) {
    for (const file of files) {
      this.dropZone.addFile(file)
    }
    this.dropZone.emit("addedFiles", files)
  }

  initMockFiles() {
    console.log("mockfiles: ", this.mockFileTargets)

    const fetchPromises = this.mockFileTargets.map(async mockFileTarget => {
      const isImage = mockFileTarget.dataset.isImage
      const fileName = mockFileTarget.dataset.name
      const dataUrl = mockFileTarget.value

      try {
        const result = await fetch(dataUrl)
        const blobText = await result.blob()
        const text = isImage ? blobText : ''
        const blob = new Blob([text])
        const mockFile = new File([blob], fileName)
        return mockFile
      } catch (err) {
        console.error(err)
        return null
      }
    })

    Promise.all(fetchPromises).then(r => this.addMockFiles(r))
  }

  // TODO multiple files
  connect() {
    console.warn("connecting")
    this.counter = 0

    this.inputTarget.classList.add('hidden')
    this.inputTarget.setAttribute('multiple', 'multiple')
    this.inputTarget.addEventListener('change', this.hiddenInputChange.bind(this))

    this.bindActions()
    this.bindEvents()
    this.dropZone = createDropZone(this, this.getLocale())
    this.initMockFiles()
  }

  disconnect() {
    console.warn("disconnecting")
    this.dropZone.resetFiles()
    this.dropZone = null
  }

  // ustawienie języka dropzone'a
  getLocale() {
    const locale = this.inputTarget.dataset.locale
    if (this.availableLocales.includes(locale)) {
      return locale
    } else {
      // jeśli nie mamy danego języka to dajemy angielski
      // (dodawanie nowych języków w../helpers/dropzone_locale.js)
      return this.availableLocales[0]
    }
  }

  hiddenInputChange() {
    const { files } = this.inputTarget
    if (files.length) {
      for (const file of files) {
        this.dropZone.addFile(file)
      }
    }
    this.dropZone.emit("addedFiles", files)
  }

  bindActions() {
    // podpiecie wszystkich akcji z tablicy actions do elementu
    this.actions.forEach(element => {
      const actions = this.element.dataset.action ? this.element.dataset.action + " " : ''
      this.element.dataset.action = actions + element
    })

    // this.messageTarget.dataset.action = 'click->intum-dropzone#clickInput'
    // this.placeholderTarget.dataset.action = 'click->intum-dropzone#clickInput'
  }

  bindEvents() {
    this.element.addEventListener("dropzone:addedFiles", event => {
      const files = Array.from(event.detail)
      // tutaj dodac kolejkowanie plikow
      files.forEach(file => {
        console.log('adding file', file)
        // to dodaje do pliku previewTemplate
        this.readAndAppendPreview(file)

        this.element.classList.add("dz-started")
        file.previewTemplate.classList.add("dz-processing")
        this.placeholderTarget.classList.add('dz-preview')
        this.placeholderTarget.classList.remove('hidden')
      })
    })

    this.element.addEventListener("dropzone:processing", event => {
      // TODO
      const file = event.detail
      console.log('dropzone:processing', file)
    })

    this.element.addEventListener("dropzone:error", event => {
      // event.preventDefault()
      const file = event.detail

      if (file.previewTemplate) {
        file.previewTemplate.classList.remove('dz-processing')
        file.previewTemplate.classList.add('dz-error')
        file.previewTemplate.classList.add("dz-complete")

        file.previewTemplate.querySelector('.dz-error-message').textContent = file.error
        file.previewTemplate.querySelector('.dz-error-message').addEventListener('click', (e) => {
          e.stopPropagation()
          this.dropZone.removeFile(file)
        })
      }
    })

    this.element.addEventListener("dropzone:success", event => {
      const file = event.detail
      console.log('dropzone:success', file)

      // TODO dodac efekt fade out do progress i success
      // success.classList.remove('hidden')
      file.previewTemplate.classList.remove('dz-processing')
      file.previewTemplate.classList.add("dz-success")
      file.previewTemplate.classList.add("dz-complete")

      let aLink = document.createElement("a")

      if (file.url && file.url.startsWith("http")) {
        aLink.href = file.url
      } else {
        aLink.href = window.location.origin + file.url
      }
      aLink.target = "_blank"
      let preview = file.previewTemplate.querySelector('.dz-details')
      aLink.classList.add('dz-details')
      aLink.innerHTML = preview.innerHTML
      file.previewTemplate.replaceChild(aLink, preview)

      aLink.addEventListener('click', (e) => {
        e.stopPropagation()
      })

      aLink = document.createElement("a")
      if (file.url && file.url.startsWith("http")) {
        aLink.href = file.url
      } else {
        aLink.href = window.location.origin + file.url
      }
      aLink.target = "_blank"
      preview = file.previewTemplate.querySelector('.dz-image')
      aLink.classList.add('dz-image')
      aLink.innerHTML = preview.innerHTML
      file.previewTemplate.replaceChild(aLink, preview)

      aLink.addEventListener('click', (e) => {
        e.stopPropagation()
      })
    })

    this.element.addEventListener("dropzone:removedFile", event => {
      const file = event.detail
      file.controller && file.controller.removeElement(file)
      file.previewTemplate && file.previewTemplate.remove()
    })

    this.element.addEventListener("dropzone:reset", () => {
      this.element.classList.remove("dz-started")

      this.placeholderTarget.classList.remove('dz-preview')
      this.placeholderTarget.classList.add('hidden')
    })

    this.element.addEventListener("dropzone:resetFiles", () => {
      console.warn("resetting files")
      this.dropZone.resetFiles()
    })
  }

  enter(e) {
    e.preventDefault()
    if (!this.isDroppable(e.dataTransfer)) return

    this.counter++
    this.toggleClass(e.dataTransfer, true)
  }

  leave(e) {
    e.preventDefault()
    if (!this.isDroppable(e.dataTransfer)) return

    this.counter--
    if (this.counter === 0) this.toggleClass(e.dataTransfer, false)
  }

  drop(e) {
    e.preventDefault()
    if (!this.isDroppable(e.dataTransfer)) return

    this.counter = 0
    this.toggleClass(e.dataTransfer, false)

    const input = this.inputTarget
    if (input) {
      input.files = e.dataTransfer.files
      input.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
      input.dispatchEvent(new Event('change', { bubbles: true, cancelable: true }))
    }
    this.dispatch('dropped', { detail: { files: e.dataTransfer.files } })
  }

  toggleClass(dataTransfer, dragover) {
    if (dragover && this.isDroppable(dataTransfer)) {
      this.element.classList.add('st-dropzone--dragover')
    } else {
      this.element.classList.remove('st-dropzone--dragover')
    }
  }

  clickInput() {
    this.inputTarget.click()
  }

  overDoc(e) {
    e.preventDefault()
    if (!this.isDroppable(e.dataTransfer)) return

    this.dragging = true
    this.dragin()
  }

  leaveDoc(e) {
    e.preventDefault()
    if (!this.isDroppable(e.dataTransfer)) return

    this.dragging = false
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if (!this.dragging) this.dragout()
    }, 200)
  }

  dropDoc(e) {
    e.preventDefault()
    if (!this.isDroppable(e.dataTransfer)) return

    this.dragging = false
    this.dragout()
  }

  dragin() {
    this.element.classList.add('st-dropzone--dragin')
  }

  dragout() {
    this.element.classList.remove('st-dropzone--dragin')
  }

  async readAndAppendPreview(file) {
    const template = this.templateTarget.content.cloneNode(true)
    const createdElement = template.querySelector('.dz-preview')
    const thumbnail = template.querySelector('.dz-image').querySelector('img')
    file.previewTemplate = createdElement

    this.setThumbnailDetails(file, createdElement)
    this.readFileToImage(file, thumbnail).then(() => {
      this.hideThumbnailDetails(createdElement)
      createdElement.addEventListener('mouseover', () => this.showThumbnailDetails(createdElement), true)
      createdElement.addEventListener('mouseout', () => this.hideThumbnailDetails(createdElement), true)
    })

    // dodajemy nowo stworzony element na poczatek listy plikow
    this.element.prepend(template)

    // zwracamy utworzony element zeby mozna bylo pozniej na nim dzialac
    return createdElement
  }

  // ustawiamy src elementu img z pliku ktory zostal przeslany
  async readFileToImage(file, element) {
    const reader = new FileReader()

    const promise = new Promise((resolve, reject) => {
      reader.addEventListener("load", () => {
        resolve(reader.result)
      }, false)

      reader.addEventListener("error", () => {
        reject(reader.error)
      }, false)
    })

    if (file) {
      reader.readAsDataURL(file)
    }

    const result = await promise
    if (file.name.endsWith('.svg')) {
      element.src = 'data:image/svg+xml;base64,' + result.split(',')[1]
    } else {
      element.src = result
    }
  }

  setThumbnailDetails(file, element) {
    const details = element.querySelector('.dz-details')
    const size = details.querySelector('.dz-size').querySelector('span')
    const name = details.querySelector('.dz-filename').querySelector('span')
    const remove = element.querySelector('.dz-remove')

    size.innerHTML = humanFilesize(file.size)
    name.innerHTML = file.name

    remove.addEventListener('click', (e) => {
      e.stopPropagation()
      this.dropZone.removeFile(file)
    })
  }

  isDroppable(dataTransfer) {
    return dataTransfer.items.length && dataTransfer.items[0].kind === 'file' && this.isAllowedByInput(dataTransfer)
  }

  isAllowedByInput(dataTransfer) {
    const input = this.inputTarget
    return !input || input.hasAttribute('multiple') || dataTransfer.items.length === 1
  }

  isResultImage(result) {
    return result && result.startsWith('data:image')
  }

  showThumbnailDetails(element) {
    const size = element.querySelector('.dz-size').querySelector('span')
    const name = element.querySelector('.dz-filename').querySelector('span')

    size.classList.remove('hidden')
    name.classList.remove('hidden')
  }

  hideThumbnailDetails(element) {
    const size = element.querySelector('.dz-size').querySelector('span')
    const name = element.querySelector('.dz-filename').querySelector('span')

    size.classList.add('hidden')
    name.classList.add('hidden')
  }
}

function createDropZone(controller, locale) {
  console.log("locale!!!")
  console.log(locale)
  console.log(locales)
  console.log(locales[locale])

  return new DropzoneObject(controller, {
    ...{
      url: controller.url,
      maxFiles: controller.maxFiles,
      maxFilesize: controller.maxFileSize,
      acceptedFiles: controller.acceptedFiles,
    },
    ...locales[locale]
  })
}
